'use client';

import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import SemiboldText from '@/components/global/typography/text/SemiboldText';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';
import { FAQSectionData, FAQSectionDataByPageName } from '@/data/static/ts/FAQ';
import PrimaryGreenLink from '@/components/global/typography/links/PrimaryGreenLink';
import DarkHeading2 from '@/components/global/typography/heading/Heading2/DarkHeading2';
import FaqAccordion from '@/components/global/collapses/FaqAccordion';


type FaqListProps = {
  page: FAQSectionDataByPageName;
};

const StyledFaqList = styled.div`
  .faq-title {
    text-align: center;
    margin-bottom: 40px !important;
  }
  

  .faq-questions-text {
    margin-top: 40px;
    text-align: left;
    .contact-us-link {
      margin-left: 8px;
    }
  }

  @media (max-width: 768px) {
    .faq-title {
      text-align: left;
    }
    .faq-questions-text {
      text-align: center;
    }
  }
`;

const FaqList: React.FC<FaqListProps> = ({ page }) => {
  const { title, FAQData } = FAQSectionData[page];
  const faqTitle = title || 'Have questions? Let’s find answers';
  return (
    <StyledFaqList>
      <PrimaryContainer>
        <Row>
          <Col xs={24} md={16} className={'ml-auto mr-auto'}>
            <DarkHeading2 className={'faq-title'}>{faqTitle}</DarkHeading2>
            <FaqAccordion items={FAQData} />
            <SemiboldText className={' faq-questions-text'}>
              Have more questions?
              <PrimaryGreenLink className={'contact-us-link'} href={'/contact'}>
                Contact us
              </PrimaryGreenLink>
            </SemiboldText>
          </Col>
        </Row>
      </PrimaryContainer>
    </StyledFaqList>
  );
};

export default FaqList;
