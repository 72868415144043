'use client';

import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { ParagraphProps } from 'antd/es/typography/Paragraph';

const MainParagraphStyled = styled.p`
  &&& {
    color: #272a3d !important;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 8px;
    margin-top: 0;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

interface MainParagraphProps extends ParagraphProps {
  children?: ReactNode;
}

const MainParagraph: React.FC<MainParagraphProps> = ({
  children,
  ...props
}) => {
  return <MainParagraphStyled {...props}>{children}</MainParagraphStyled>;
};

export default MainParagraph;
