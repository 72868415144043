import styled from "styled-components";
import React, {ReactNode} from "react";
import {ParagraphProps} from "antd/es/typography/Paragraph";


const WhiteParagraphStyled = styled.p`
  &&& {
    color: #ffffff !important;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;

    @media(max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
    }
  }
`;

interface WhiteParagraphProps extends ParagraphProps {
    children: ReactNode;
}

const WhiteParagraph: React.FC<WhiteParagraphProps> = ({ children, ...props }) => {
  return (
        <WhiteParagraphStyled {...props}>
          {children}
        </WhiteParagraphStyled>
  );
};


export default  WhiteParagraph
