import styled from 'styled-components';
import { Accordion } from 'react-accessible-accordion';

const StyledFaqAccordion = styled(Accordion)`

    margin-bottom: 40px;
  border-radius: 0;
  width: 100%;

    .accordion__item {
        border-top: 1px solid #eaeaec;
        .accordion__button {
            padding: 24px 16px;
            h3 {
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
                padding-left: 0;
                text-align: left;
            }
        }
        .accordion__panel {
                padding: 0 16px 24px;
            animation: fadein 0.25s ease-in;
        }
        &:last-child {
            border-bottom: 1px solid #eaeaec;
            border-radius: 0;
        }
    }

    .accordion__button {       
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        gap: 18px
    }

     .expand_arrow {
        display: block;
        content: '';
        height: 8px !important;
        width: 8px !important;
        min-width: 8px !important;
        border-bottom: 1px solid currentColor;
        border-right: 1px solid currentColor;
        transform: rotate(-45deg);
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
    }

 .accordion__button[aria-expanded='true'] .expand_arrow,
   .accordion__button[aria-selected='true'] .expand_arrow {
        transform: rotate(45deg);
        -webkit-transition: all 300ms ease;
        -moz-transition: all 300ms ease;
        -ms-transition: all 300ms ease;
        -o-transition: all 300ms ease;
        transition: all 300ms ease;
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

`;

export default StyledFaqAccordion;
