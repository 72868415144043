'use client';

import styled from 'styled-components';
import MainLink from '@/components/global/typography/links/MainLink';

const PrimaryGreenLink = styled(MainLink)`
  &&& {
    text-decoration: underline;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #1a847c;
    transition: 0.2s;
    &:hover {
      color: #12635c;
      text-decoration: none;
    }
  }
`;

export default PrimaryGreenLink;
