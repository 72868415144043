'use client';

import {
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItem,
} from 'react-accessible-accordion';
import { IFaqItem } from '@/types';
import DarkHeading3 from '@/components/global/typography/heading/Heading3/DarkHeading3';
import MainParagraph from '@/components/global/typography/paragraph/MainParagraph';
import StyledFaqAccordion from '@/components/global/collapses/FaqAccordion/style';

interface IFaqAccordionProps {
  items: IFaqItem[];
}

const FaqAccordion = ({ items }: IFaqAccordionProps) => {

  return (
    <StyledFaqAccordion allowZeroExpanded allowMultipleExpanded>
      {items.map((item, index) => (
        <AccordionItem key={index}>
          <AccordionItemHeading>
            <AccordionItemButton>
              <DarkHeading3>{item.question}</DarkHeading3>
              <div className={'expand_arrow'} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel >
              <MainParagraph className="mb-0 whitespace-pre-line">
                {item.answer}
              </MainParagraph>
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </StyledFaqAccordion>
  );
};

export default FaqAccordion;
