'use client';

import styled from 'styled-components';
import { Typography } from 'antd';
import React, { ReactNode } from 'react';
import { TitleProps } from 'antd/es/typography/Title';

const { Title } = Typography;

const DarkHeading4Styled = styled(Title)`
  &&& {
    color: #272a3d !important;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
`;

interface DarkHeading4Props extends TitleProps {
  children: ReactNode;
}

const DarkHeading4: React.FC<DarkHeading4Props> = ({ children, ...props }) => {
  return (
    <DarkHeading4Styled level={4} {...props}>
      {children}
    </DarkHeading4Styled>
  );
};

export default DarkHeading4;
