'use client';

import styled from 'styled-components';
import React, { CSSProperties } from 'react';

interface ContainerProps {
  $bgColor?: string;
  className?: string;
  style?: CSSProperties;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  margin: 0 auto;
  background: ${(props) => props.$bgColor || 'transparent'};
`;

interface FullWidthContainerProps extends ContainerProps {
  children: React.ReactNode;
  bgColor?: string;
}

const ContainerFluid = ({
  children,
  bgColor,
  className,
  style,
}: FullWidthContainerProps) => {
  return (
    <Container className={className} $bgColor={bgColor} style={style}>
      {children}
    </Container>
  );
};

export default ContainerFluid;
