import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { TextProps } from 'antd/es/typography/Text';

const SemiboldTextStyled = styled.span`
  &&& {
    color: #272a3d !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0px;
  }
`;

interface SemiboldTextProps extends TextProps {
  children: ReactNode;
}

const SemiboldText: React.FC<SemiboldTextProps> = ({ children, ...props }) => {
  return <SemiboldTextStyled {...props}>{children}</SemiboldTextStyled>;
};

export default SemiboldText;
