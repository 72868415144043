import styled from "styled-components";
import { Typography } from "antd";
import React, {ReactNode} from "react";
import {TitleProps} from "antd/es/typography/Title";

const { Title } = Typography;

const WhiteTitleStyled = styled(Title)`
  &&& {
    color: #ffffff !important;
    font-weight: 700;
    font-size: 38px;
    line-height: 54px;
    margin: 0px;

    @media (max-width: 768px) {
      font-size: 30px;
      line-height: 42px;
    }
  }

 
`;

interface WhiteTitleProps  extends TitleProps {
    children: ReactNode;
}

const WhiteHeading2:React.FC<WhiteTitleProps> = ({ children,  ...props }) => {
  return (
      <div>
        <WhiteTitleStyled level={2} {...props}>
          {children}
        </WhiteTitleStyled>
      </div>
  );
};

export default  WhiteHeading2
